@font-face {
  font-family: 'es-display';
  src: url("../fonts/es-display.eot");
  src: url("../fonts/es-display.eot?#iefix") format("embedded-opentype"), url("../fonts/es-display.woff2") format("woff2"), url("../fonts/es-display.woff") format("woff"), url("../fonts/es-display.ttf") format("truetype"), url("../fonts/es-display.svg#es-display") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'es-text';
  src: url("../fonts/es-text.eot");
  src: url("../fonts/es-text.eot?#iefix") format("embedded-opentype"), url("../fonts/es-text.woff2") format("woff2"), url("../fonts/es-text.woff") format("woff"), url("../fonts/es-text.ttf") format("truetype"), url("../fonts/es-text.svg#es-text") format("svg");
  font-weight: normal;
  font-style: normal; }

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

*:focus {
  outline: none; }

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  font-family: es-text, sans-serif;
  line-height: 1.5rem;
  font-size: 24px;
  color: #1C2F4D;
  background-color: #FAFAFA; }

a {
  text-decoration: underline;
  cursor: pointer;
  color: #1C2F4D;
  transition: all 0.3s cubic-bezier(0.58, -0.19, 0.54, 1.05); }
  a:hover {
    color: #448CFF; }
    @media only screen and (max-width: 768px) {
      a:hover {
        color: #1C2F4D; } }

.force-space {
  display: inline-block;
  padding: 0 13.5px; }

@media only screen and (max-width: 1280px) {
  .only-desktop {
    display: none; } }

.container {
  margin: 0 auto;
  padding: 18px 45px;
  max-width: 1287px; }
  .container.fluid {
    max-width: 100%;
    padding: 90px; }
  @media only screen and (max-width: 1280px) {
    .container {
      max-width: 1000px; } }
  @media only screen and (max-width: 1024px) {
    .container {
      max-width: 800px; } }
  @media only screen and (max-width: 768px) {
    .container {
      max-width: 100%; } }
  @media only screen and (max-width: 768px) {
    .container {
      padding: 9px 9px;
      max-width: 100%; } }

.row {
  margin: 0 auto;
  max-width: 1197px;
  font-size: 0;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 1280px) {
    .row {
      max-width: 800px; } }
  @media only screen and (max-width: 768px) {
    .row {
      max-width: 100%; } }
  .row.fluid {
    max-width: 100%; }

.col.col-center {
  text-align: center; }

.col.col-1 {
  width: 8.33333%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-1 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-1.fluid {
    width: 8.33333%; }

.col.col-2 {
  width: 16.66667%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-2 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-2.fluid {
    width: 16.66667%; }

.col.col-3 {
  width: 25%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-3 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-3.fluid {
    width: 25%; }

.col.col-4 {
  width: 33.33333%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-4 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-4.fluid {
    width: 33.33333%; }

.col.col-5 {
  width: 41.66667%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-5 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-5.fluid {
    width: 41.66667%; }

.col.col-6 {
  width: 50%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-6 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-6.fluid {
    width: 50%; }

.col.col-7 {
  width: 58.33333%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-7 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-7.fluid {
    width: 58.33333%; }

.col.col-8 {
  width: 66.66667%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-8 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-8.fluid {
    width: 66.66667%; }

.col.col-9 {
  width: 75%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-9 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-9.fluid {
    width: 75%; }

.col.col-10 {
  width: 83.33333%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-10 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-10.fluid {
    width: 83.33333%; }

.col.col-11 {
  width: 91.66667%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-11 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-11.fluid {
    width: 91.66667%; }

.col.col-12 {
  width: 100%;
  display: inline-block;
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .col.col-12 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }
  .col.col-12.fluid {
    width: 100%; }

@media only screen and (max-width: 1280px) {
  .col.col-t-3 {
    width: 25%;
    display: inline-block;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top; } }
  @media only screen and (max-width: 1280px) and (max-width: 768px) {
    .col.col-t-3 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }

@media only screen and (max-width: 1280px) {
    .col.col-t-3.fluid {
      width: 25%; } }

@media only screen and (max-width: 1280px) {
  .col.col-t-6 {
    width: 50%;
    display: inline-block;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top; } }
  @media only screen and (max-width: 1280px) and (max-width: 768px) {
    .col.col-t-6 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }

@media only screen and (max-width: 1280px) {
    .col.col-t-6.fluid {
      width: 50%; } }

@media only screen and (max-width: 1280px) {
  .col.col-t-12 {
    width: 100%;
    display: inline-block;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top; } }
  @media only screen and (max-width: 1280px) and (max-width: 768px) {
    .col.col-t-12 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }

@media only screen and (max-width: 1280px) {
    .col.col-t-12.fluid {
      width: 100%; } }

@media only screen and (max-width: 768px) {
  .col.col-m-3 {
    width: 25%;
    display: inline-block;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top; } }
  @media only screen and (max-width: 768px) and (max-width: 768px) {
    .col.col-m-3 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }

@media only screen and (max-width: 768px) {
    .col.col-m-3.fluid {
      width: 25%; } }

@media only screen and (max-width: 768px) {
  .col.col-m-6 {
    width: 50%;
    display: inline-block;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top; } }
  @media only screen and (max-width: 768px) and (max-width: 768px) {
    .col.col-m-6 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }

@media only screen and (max-width: 768px) {
    .col.col-m-6.fluid {
      width: 50%; } }

@media only screen and (max-width: 768px) {
  .col.col-m-12 {
    width: 100%;
    display: inline-block;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top; } }
  @media only screen and (max-width: 768px) and (max-width: 768px) {
    .col.col-m-12 {
      width: 100% !important;
      padding-left: 18px !important;
      padding-right: 18px !important; } }

@media only screen and (max-width: 768px) {
    .col.col-m-12.fluid {
      width: 100%; } }

p {
  font-family: es-text, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0 0 1.5rem 0;
  max-width: 882px;
  color: #1C2F4D; }
  p.p-b {
    font-size: 1.625rem;
    line-height: 2.25rem;
    max-width: 1008px;
    padding-bottom: 2.25rem; }
    @media only screen and (max-width: 768px) {
      p.p-b {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-bottom: 1.5rem; } }
  p.p-s {
    font-size: 0.75rem;
    line-height: 1.125rem;
    max-width: 648px;
    padding-bottom: 1.125rem;
    color: #1C2F4D; }
  p.p-center {
    margin: 0 auto;
    max-width: 720px; }
    p.p-center.p-b {
      max-width: 1008px; }

.highlight {
  color: #E39AC8;
  position: relative;
  z-index: 0;
  /*&:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 12px solid color(blue);
    z-index: -1;
  }*/ }

h1, h2, h3, h4 {
  font-family: es-display, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: bold;
  max-width: 90%;
  font-feature-settings: "kern", "liga", "pnum", "ss04";
  -moz-font-feature-settings: "salt" 2;
  -webkit-font-feature-settings: "salt" 2;
  font-feature-settings: "salt" 2; }

h1 {
  letter-spacing: -0.02em;
  font-size: 3.58333rem;
  line-height: 4.125rem;
  max-width: 1260px;
  margin-top: 2.0625rem;
  margin-bottom: 2.0625rem; }
  @media only screen and (max-width: 1280px) {
    h1 {
      font-size: 2.625rem;
      line-height: 3rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem; } }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 1.625rem;
      line-height: 1.875rem;
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem; } }

h2 {
  font-size: 2.625rem;
  line-height: 3rem;
  max-width: 1098px;
  margin-top: 3rem;
  margin-bottom: 1.5rem; }
  @media only screen and (max-width: 1280px) {
    h2 {
      font-size: 1.625rem;
      line-height: 1.875rem;
      margin-top: 1.875rem;
      margin-bottom: 0.9375rem; } }

h3 {
  font-size: 1.625rem;
  line-height: 1.875rem;
  max-width: 882px;
  margin-top: 1.875rem;
  margin-bottom: 0.9375rem; }

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 882px;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase; }

.headline.headline-center {
  max-width: 100% !important; }

.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 0.8s cubic-bezier(0.91, -0.22, 0.76, 0.91); }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: all 0.8s cubic-bezier(0.21, 0, 0.35, 1.29); }

div.transition-group {
  position: relative; }

.page-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }
