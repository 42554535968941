.image {
  width: 100%;
  position: relative;
  display: inline-block;
  min-height: 162px;
  transition: all 0.8s cubic-bezier(0.58, -0.19, 0.54, 1.05);
  margin-bottom: 162px; }
  .image.image-right {
    text-align: right; }
  @media only screen and (max-width: 1024px) {
    .image {
      margin-bottom: 90px; } }
  @media only screen and (max-width: 768px) {
    .image {
      margin-bottom: 36px; } }
  .image img {
    width: 100%; }
  .image.image-nopadding {
    margin-bottom: 0; }
  @media only screen and (max-width: 768px) {
    .image.image-full img {
      min-width: 1440px;
      margin-left: 50%;
      transform: translateX(-50%); } }
  .image.image-full.image-maxed img {
    max-width: 1440px; }
  .image.image-device {
    padding: 18px;
    border-radius: 36px;
    background-color: #1C2F4D;
    position: relative;
    width: 100%;
    box-shadow: inset 6px 6px 6px rgba(255, 255, 255, 0.05), inset -6px -6px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 162px; }
    .image.image-device img {
      width: 100%;
      border-radius: 18px;
      display: block;
      font-size: 0; }
    .image.image-device:before {
      position: absolute;
      content: '';
      top: calc(100% - 54px);
      left: 0;
      z-index: 0;
      width: 100%;
      background-position: center;
      background-size: 100% 100%;
      height: 162px;
      background-image: url("../../resources/img/image_device_shadow.png");
      opacity: .5; }
    .image.image-device.image-mobile {
      max-width: 411px; }
      @media only screen and (max-width: 1024px) {
        .image.image-device.image-mobile {
          padding: 9px;
          border-radius: 18px; }
          .image.image-device.image-mobile img {
            border-radius: 9px; } }
    .image.image-device.image-tablet {
      max-width: 768px; }
      @media only screen and (max-width: 1024px) {
        .image.image-device.image-tablet {
          padding: 9px;
          border-radius: 18px; }
          .image.image-device.image-tablet img {
            border-radius: 9px; } }
    .image.image-device.image-desktop {
      max-width: 100%; }
      @media only screen and (max-width: 1024px) {
        .image.image-device.image-desktop {
          padding: 9px;
          border-radius: 18px; }
          .image.image-device.image-desktop img {
            border-radius: 9px; } }
    @media only screen and (max-width: 1024px) {
      .image.image-device {
        margin-bottom: 90px; } }
    @media only screen and (max-width: 768px) {
      .image.image-device {
        margin-bottom: 36px; } }
  .image + .p-s {
    margin-top: -90px;
    z-index: 10;
    position: relative;
    opacity: .5; }
    @media only screen and (max-width: 768px) {
      .image + .p-s {
        margin-top: -18px; } }
