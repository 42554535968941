.info {
  padding: 0 0 144px; }
  .info .info__link {
    margin-right: 18px; }
  .info .info__content {
    padding-top: 90px; }
    @media only screen and (max-width: 1280px) {
      .info .info__content h1 br {
        display: none; } }
  .info .info__get-in-touch h3 {
    margin-top: 0; }
  .info .anim-container {
    position: fixed;
    width: 100%;
    pointer-events: none;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%; }
