.blog-post-hero-image {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative; }
  .blog-post-hero-image .blog-post-hero-image__text {
    position: absolute;
    max-width: 33%;
    background-color: #2D3035;
    bottom: 0;
    left: 0;
    padding: 36px 36px;
    color: #FAFAFA; }
    @media only screen and (max-width: 480px) {
      .blog-post-hero-image .blog-post-hero-image__text {
        max-width: 100%;
        font-size: 0.75rem;
        line-height: 1.125rem;
        padding: 36px 27px; } }
