.projects {
  line-height: 0; }
  .projects .projects__more {
    padding-top: 144px;
    padding-bottom: 144px; }
    @media only screen and (max-width: 768px) {
      .projects .projects__more {
        padding: 90px 36px 45px 36px;
        text-align: center; }
        .projects .projects__more a {
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
        .projects .projects__more a + br {
          display: none; } }
  .projects .projects__project {
    display: inline-block;
    width: 100%;
    margin: 0; }
    .projects .projects__project .projects__project__card {
      padding: 144px 0;
      position: relative;
      min-height: 340px;
      overflow: hidden;
      margin: 0; }
      .projects .projects__project .projects__project__card:before {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FAFAFA;
        content: '';
        opacity: .75; }
      .projects .projects__project .projects__project__card .projects__project__card__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        filter: grayscale(100%) contrast(30%) brightness(80%);
        z-index: 2; }
        .projects .projects__project .projects__project__card .projects__project__card__image img {
          position: absolute;
          display: block;
          height: 104%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .projects .projects__project .projects__project__card.projects__project__card-light h1, .projects .projects__project .projects__project__card.projects__project__card-light p, .projects .projects__project .projects__project__card.projects__project__card-light a {
        color: #FAFAFA; }
      .projects .projects__project .projects__project__card.projects__project__card-light:before {
        background-color: #1C2F4D; }
      .projects .projects__project .projects__project__card.projects__project__card-light .projects__project__card__image {
        filter: grayscale(100%) contrast(20%); }
      .projects .projects__project .projects__project__card h1 {
        margin-top: 0;
        margin-bottom: 18px;
        position: relative;
        z-index: 5; }
      .projects .projects__project .projects__project__card p {
        padding: 0 0 9px;
        position: relative;
        z-index: 5; }
      .projects .projects__project .projects__project__card a {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10; }
      .projects .projects__project .projects__project__card:hover h1, .projects .projects__project .projects__project__card:hover p {
        z-index: 2; }
      .projects .projects__project .projects__project__card:hover:before {
        opacity: 0; }
      .projects .projects__project .projects__project__card:hover .projects__project__card__image {
        z-index: 9;
        filter: none; }
      @media only screen and (max-width: 768px) {
        .projects .projects__project .projects__project__card {
          padding: 45px 36px 45px 36px;
          min-height: 0px;
          text-align: center; }
          .projects .projects__project .projects__project__card:before {
            display: none; }
          .projects .projects__project .projects__project__card .projects__project__card__image {
            display: none;
            filter: none; }
          .projects .projects__project .projects__project__card.projects__project__card-light .projects__project__card__image {
            display: none;
            filter: none; }
          .projects .projects__project .projects__project__card:hover .projects__project__card__image {
            z-index: 2; } }
