.global-nav {
  font-size: 0.75rem;
  line-height: 1.125rem;
  position: relative;
  z-index: 8888; }
  @media only screen and (max-width: 768px) {
    .global-nav {
      margin-left: 63px; } }
  .global-nav .global-nav__home-link-container {
    position: fixed;
    z-index: 9999;
    transform-origin: top left;
    transform: rotate(-90deg);
    left: 9px;
    top: 90px; }
  .global-nav .global-nav__post-title {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #1C2F4D;
    max-width: 95%; }
    .global-nav .global-nav__post-title span {
      font-family: es-display, sans-serif;
      font-weight: bold; }
  .global-nav.global-nav-in-project .global-nav__home-link-container, .global-nav.global-nav-in-post .global-nav__home-link-container {
    top: 77px; }
  .global-nav.global-nav-in-post .global-nav__link, .global-nav.global-nav-in-post .global-nav__email-link {
    display: none; }
  @media only screen and (max-width: 768px) {
    .global-nav.global-nav-no-nav {
      margin-left: 27px; } }
  .global-nav .global-nav__home-link {
    font-family: es-display, sans-serif;
    text-decoration: none;
    position: absolute;
    background-color: #FFF;
    padding: 9px 14px 9px 14px;
    top: -9px;
    left: -4px;
    font-size: 1rem;
    line-height: 1.5rem; }
    .global-nav .global-nav__home-link:hover {
      color: #1C2F4D; }
  .global-nav .global-nav__link {
    margin-right: 18px; }
    .global-nav .global-nav__link.global-nav__link-active {
      color: #1C2F4D;
      opacity: .5;
      text-decoration: none; }
      .global-nav .global-nav__link.global-nav__link-active:hover {
        color: #1C2F4D; }
