.placeholder {
  width: 100%;
  position: relative;
  text-align: center; }
  .placeholder .placeholder__loader {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: #1C2F4D;
    opacity: .5;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -18px;
    margin-top: -18px;
    animation: pulse 1s infinite;
    animation-direction: alternate; }
  .placeholder.placeholder-light .placeholder__loader {
    background-color: #FAFAFA; }

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  100% {
    opacity: .5;
    transform: scale(1); } }
