.projects-nav {
  font-size: 0.75rem;
  line-height: 1.125rem;
  position: relative;
  padding-top: 9px; }
  @media only screen and (max-width: 1024px) {
    .projects-nav {
      white-space: nowrap;
      overflow-x: auto; } }
  @media only screen and (max-width: 768px) {
    .projects-nav {
      white-space: nowrap;
      overflow-x: auto;
      padding-top: 18px;
      margin-left: 63px; } }
  .projects-nav .projects-nav__link {
    margin-right: 18px; }
    .projects-nav .projects-nav__link.projects-nav__link-active {
      color: #1C2F4D;
      opacity: .5;
      text-decoration: none; }
      .projects-nav .projects-nav__link.projects-nav__link-active:hover {
        color: #1C2F4D; }
