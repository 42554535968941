.project-structure {
  height: 100%;
  position: relative;
  padding: 72px 0; }
  .project-structure.project-structure-full {
    width: 100%;
    overflow-x: hidden; }
  .project-structure.project-structure-light {
    color: #FAFAFA; }
    .project-structure.project-structure-light h1, .project-structure.project-structure-light h2, .project-structure.project-structure-light p {
      color: #FAFAFA; }
    .project-structure.project-structure-light a {
      color: #FAFAFA; }
      .project-structure.project-structure-light a:hover {
        color: #FAFAFA !important;
        opacity: .5; }
    .project-structure.project-structure-light .project-structure__next a:hover {
      color: #FAFAFA;
      opacity: .5; }
  .project-structure .project-structure__content {
    padding-top: 144px;
    padding-bottom: 72px; }
    .project-structure .project-structure__content p:first-child {
      margin-top: 63px;
      margin-bottom: 63px; }
    .project-structure .project-structure__content a:hover {
      color: #1C2F4D;
      opacity: .5; }
    @media only screen and (max-width: 768px) {
      .project-structure .project-structure__content {
        padding-top: 36px;
        padding-bottom: 36px; }
        .project-structure .project-structure__content p:first-child {
          margin-top: 18px;
          margin-bottom: 36px; } }
  .project-structure .project-structure__next {
    padding: 72px 0;
    font-size: 1.625rem;
    line-height: 2.25rem;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .project-structure .project-structure__next {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 36px 0; } }
    .project-structure .project-structure__next a:hover {
      color: #1C2F4D;
      opacity: .5; }
  .project-structure .project-structure__info {
    overflow: hidden; }
    .project-structure .project-structure__info h1 {
      padding-top: 72px;
      margin: 0; }
      @media only screen and (min-width: 1440px) {
        .project-structure .project-structure__info h1 {
          font-size: 16vw;
          line-height: 0.9; } }
    .project-structure .project-structure__info .project-structure__info__description {
      vertical-align: bottom; }
      @media only screen and (min-width: 1440px) {
        .project-structure .project-structure__info .project-structure__info__description {
          vertical-align: top; } }
      .project-structure .project-structure__info .project-structure__info__description p {
        margin: 0;
        padding: 0 0 9px; }
    @media only screen and (max-width: 768px) {
      .project-structure .project-structure__info {
        text-align: center; }
        .project-structure .project-structure__info h1 {
          font-size: 2.625rem;
          line-height: 3rem;
          margin-bottom: 36px;
          word-break: normal;
          max-width: 100%; } }
